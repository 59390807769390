import authModule from '@/store/auth'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import http from '@/services/http'
import Legal from '@/Models/Legal'
import Crm from '@/Models/Crm'
import * as Helpers from '@/helpers'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormLegal extends Vue {
  @Prop({ required: false, default: null })
  objRow!: Legal
  @Prop({ required: false, default: null })
  isAction!: string
  //el mae seleccionado
  objLegal: Legal = new Legal()
  loading = false
  date: any = new Date()
  name = ''
  isFileEdit = false
  listTypeProcedure = [
    { id: 1, name: 'FIJACION ALIMENTARIA', value: 1 },
    { id: 2, name: 'xd', value: 2 },
  ]

  listMemberNumber: Array<Crm> = []

  selected!: any
  get is_login(): boolean {
    return authModule.isAuthenticated
  }
  mounted() {
    this.getNumberMember()
    if (this.objRow && this.isAction == 'edit') {
      if (this.objLegal.member_number == undefined) {
        this.selected = this.objRow.member_number
      }
      this.$set(this, 'objLegal', this.objRow)
      this.$set(
        this.objLegal,
        'inputMemberNumber',
        this.objRow.member_number.client_name,
      )
    }
  }

  get filteredDataObj() {
    const filterData = this.listMemberNumber.filter(
      (option) =>
        option.client_name
          .toString()
          .toLowerCase()
          .indexOf(this.objLegal.inputMemberNumber) >= 0,
    )
    return filterData
    //return filterData.map((x:any) =>  x.document_type)
  }
  async confirmation() {
    const Legal = this.LegalForm(this.objLegal)
    switch (this.isAction) {
      case 'add':
        await this.createObjLegal(Legal)
        break
      case 'edit':
        await this.updateObjLegal(Legal)
        break
      default:
        console.log('ups')
        break
    }
  }
  async createObjLegal(objLegal: any) {
    try {
      await http.post(`api/legal/arcr_legal/`, objLegal)
      this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      console.error(error.data)
      this.loading = false
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  async updateObjLegal(objLegal: any) {
    try {
      await http.put(`api/legal/arcr_legal/${this.objRow.id}/`, objLegal)
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      //console.error(error)
      this.loading = false
      switch (error.response.data.member_number[0]) {
        case 'Ya existe Crm Cliente Archivo con este member number.':
          this.$buefy.toast.open({
            message: `Ya exite un cliente con el mismo numero de miembro`,
            type: 'is-danger',
          })
          break
        default:
          this.$buefy.toast.open({
            message: `Ups!, algo salio mal`,
            type: 'is-danger',
          })
          break
      }
      /* this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: "is-danger",
      }) */
    }
  }
  async getNumberMember() {
    this.loading = true
    try {
      const res = await http.get('api/crm/members_list/')
      if (!res.data) throw new Error(res.data)
      this.listMemberNumber = res.data.map((obj: any) => ({
        ...obj,
      }))
    } catch (error) {
      //console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }

  LegalForm(obj: Legal) {
    const Legal = {
      id: obj.id,
      member_number_id: this.selected.member_number,
      court_name: obj.court_name,
      file_number: obj.file_number,
      date_registered: Helpers.dateFormat_db(obj.date_registered),
      tramit_type: obj.procedure_type,
      observation: obj.observation,
    }
    return Legal
  }
  close() {
    this.objLegal = new Legal()
    this.$emit('cleanForm')
  }
}

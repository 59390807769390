var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"form"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Member name'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-autocomplete',{attrs:{"placeholder":_vm.$t('Ex:') + ' Juan Pérez',"keep-first":true,"open-on-focus":true,"field":"client_name","data":_vm.filteredDataObj,"clearable":true},on:{"select":function (option) { return (_vm.selected = option); }},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_vm._v(" "+_vm._s(props.option.client_name)+" ")])])]}}],null,true),model:{value:(_vm.objLegal.inputMemberNumber),callback:function ($$v) {_vm.$set(_vm.objLegal, "inputMemberNumber", $$v)},expression:"objLegal.inputMemberNumber"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Name of court'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' GRIFFITH RICKEY S'},model:{value:(_vm.objLegal.court_name),callback:function ($$v) {_vm.$set(_vm.objLegal, "court_name", $$v)},expression:"objLegal.court_name"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Case number'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 11-00-000-EP'},model:{value:(_vm.objLegal.file_number),callback:function ($$v) {_vm.$set(_vm.objLegal, "file_number", $$v)},expression:"objLegal.file_number"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('State'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objLegal.procedure_type),callback:function ($$v) {_vm.$set(_vm.objLegal, "procedure_type", $$v)},expression:"objLegal.procedure_type"}},_vm._l((_vm.listTypeProcedure),function(option){return _c('option',{key:option.id,domProps:{"value":option.name}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Filing date'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objLegal.date_registered),callback:function ($$v) {_vm.$set(_vm.objLegal, "date_registered", $$v)},expression:"objLegal.date_registered"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('General Observations of the Case'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"maxlength":"200","type":"textarea","placeholder":_vm.$t('Ex:') + ' Terminado...'},model:{value:(_vm.objLegal.observation),callback:function ($$v) {_vm.$set(_vm.objLegal, "observation", $$v)},expression:"objLegal.observation"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button btn-save",attrs:{"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.confirmation)}}},[_vm._v(" Guardar ")]),_c('button',{staticClass:"button btn-cancel",on:{"click":_vm.close}},[_vm._v("Cancelar")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }